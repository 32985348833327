import { Card, Elevation, Radio } from '@blueprintjs/core';
import { useField } from 'formik';
import { formatVehicleImage } from '../../lib/Utility';
import FieldError from '../form/FieldError';
import { UnloadMethodSelect } from '../UnloadMethodSelect';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../lib/store';
import {
  fetchVehicleTypes,
  selectVehicleTypes,
} from '../../lib/reducers/estimateSlice';
import {
  VehicleClass,
  VehicleTypeWithClasses,
} from '../../lib/actions/VehicleAction';

type VehicleCardProps = {
  vehicleType: VehicleTypeWithClasses;
  selected: VehicleTypeWithClasses | null;
  onChange: (vehicleType: VehicleTypeWithClasses) => void;
};

function VehicleCard({ vehicleType, selected, onChange }: VehicleCardProps) {
  const isSelected = vehicleType.id === selected?.id;

  let className = isSelected
    ? 'card cardSelected vehicleType'
    : 'card vehicleType';

  if (vehicleType.classes.length <= 1) {
    className += ' hiddenVehicleClass';
  }

  return (
    <Card
      interactive
      elevation={isSelected ? Elevation.THREE : Elevation.ONE}
      onClick={() => onChange(vehicleType)}
      className={className}
    >
      <div className='cardImage'>
        <img
          src={formatVehicleImage(vehicleType.key, isSelected)}
          alt='Frayt'
        />
      </div>
      <div className='cardText'>
        <h3>{vehicleType.name}</h3>
      </div>
    </Card>
  );
}
type VehicleClassSelectProps = {
  vehicleClass: VehicleClass;
};

function VehicleClassSelect({ vehicleClass }: VehicleClassSelectProps) {
  const [{ value: selectedVehicleClass }, , { setValue, setTouched }] =
    useField<string>('vehicle_class_id');

  const selectVehicleClass = useCallback(() => {
    setTouched(true, false);
    setValue(vehicleClass.id);
  }, [vehicleClass.id, setValue, setTouched]);

  const isSelected = selectedVehicleClass === vehicleClass.id;

  return (
    <Card
      interactive
      elevation={isSelected ? Elevation.FOUR : Elevation.TWO}
      onClick={selectVehicleClass}
      className={
        isSelected ? 'card cardSelected vehicleClass' : 'card vehicleClass'
      }
    >
      <Radio
        label={vehicleClass.name}
        className='radioLabel'
        checked={isSelected}
        readOnly
      />
      <div
        className='cardText'
        dangerouslySetInnerHTML={{ __html: vehicleClass.capacity_description }}
      />
    </Card>
  );
}

export function VehicleSelect() {
  const [{ value }, , { setValue, setTouched }] = useField<string | undefined>(
    'vehicle_class_id'
  );
  const vehicleTypes = useAppSelector(selectVehicleTypes);
  const [selected, setSelected] = useState<VehicleTypeWithClasses | null>(null);
  const dispatch = useAppDispatch();

  const handleChange = useCallback(
    (type: VehicleTypeWithClasses) => {
      setSelected(type);
      setValue(type?.classes[0]?.id);
      setTouched(true, false);
    },
    [setValue, setTouched]
  );

  useEffect(() => {
    dispatch(fetchVehicleTypes());
  }, [dispatch]);

  useEffect(() => {
    const type =
      vehicleTypes.find(vt => vt.classes.find(c => c.id === value)) ||
      vehicleTypes[0];

    if (type?.id !== selected?.id) {
      if (!type) return setSelected(null);

      const sortedType = {
        ...type,
        classes: [...type.classes].sort((a, b) => a.index - b.index),
      };

      setSelected(sortedType);
    } else {
      const isSelectedType = selected?.classes.some(c => c.id === value);
      const vehicleClassId = selected?.classes[0]?.id;

      if (!isSelectedType && vehicleClassId && vehicleClassId !== value) {
        setTouched(true, false);
        setValue(vehicleClassId);
      }
    }
  }, [vehicleTypes, value, selected, setValue, setTouched]);

  return (
    <>
      <div className='vehicleSelectWrapper'>
        {vehicleTypes.map(vehicleType => (
          <VehicleCard
            key={vehicleType.id}
            vehicleType={vehicleType}
            selected={selected}
            onChange={handleChange}
          />
        ))}
      </div>

      {selected?.classes && selected.classes.length > 1 && (
        <div className='vehicleClassSelectWrapper'>
          {selected?.classes.map(vehicleClass => (
            <VehicleClassSelect
              key={vehicleClass.id}
              vehicleClass={vehicleClass}
            />
          ))}
        </div>
      )}

      <FieldError name='vehicle_class' />

      {selected?.legacy_id === 4 && <UnloadMethodSelect />}
    </>
  );
}
